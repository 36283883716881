
  import DeviceDemo from '@/components/DeviceDemo.vue'
  import { prismicLink } from '@/utils/url'

  export default {
    name: 'AppDemo',
    props: {
      slice: {
        type: Object,
        required: true,
        default() {
          return {}
        },
      },
    },
    computed: {
      link() {
        return prismicLink(
          this.slice.primary.link,
          this.$prismic.asText(this.slice.primary.linkLabel)
        )
      },
      contentAspectRatio() {
        return this.slice.primary.demoContentType === 'image'
          ? Math.round(
              (this.slice.primary.demoContent.width /
                this.slice.primary.demoContent.height +
                Number.EPSILON) *
                10
            ) / 10
          : 1
      },
    },
    components: { DeviceDemo },
  }
